<template>
  <div>
    <div class="technicalServiceSupport">
      <div>1688 より、一部技術、情報サポートを受けて提供させて頂いております</div>
    </div>
    <div class="footerBackground">
      <div class="footerLinkBox">
        <div class="footerLinkLeftBox">
          <img src="../../assets/logo2.png" alt="">
          <div style="margin-bottom:22px">services@rakumart.com</div>
          <div>代行専用：06-7636-8120</div>
          <div style="margin-bottom:23px">10:00~18:30(日本時間)</div>
          <!--          <div>Copyright©2018, Rakumart.com</div>-->
          <el-link href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18023127号-7</el-link>
        </div>
        <div class="footerLinkRightBox flex">
          <div class="boxOpt">
            <div class="fontSize18 color333 fontWeightBold" style="margin-bottom: 29px">{{ $fanyi("用户指南") }}</div>
            <div class="color666 margin-bottom-6 cursorPointer" @click="pageJump('/register')">
              {{ $fanyi("無料会員登録") }}
            </div>
            <!--            <div class="color666 margin-bottom-6 cursorPointer">{{ $fanyi("利用ガイド") }}</div>-->
            <div class="color666 margin-bottom-6 cursorPointer"
                 @click="pageJump('/membershipUtilizationProtocol')">{{ $fanyi("会員利用規約") }}
            </div>
            <div class="color666 cursorPointer margin-bottom-6" @click="pageJump('/privacyPolicy')">{{
                $fanyi("プライバシーポリシー")
              }}
            </div>
            <div class="color666 margin-bottom-6 cursorPointer"
                 @click="pageJump('/disclaimer')">
              {{ $fanyi("返品・返金及び免責事項") }}
            </div>
            <div class="color666 cursorPointer"
                 @click="pageJump('/guia-de-usuario')">
              {{ $fanyi("用户指南") }}
            </div>
          </div>
          <div class="boxOpt">
            <div class="fontSize18 color333 fontWeightBold" style="margin-bottom: 29px">{{
                $fanyi("会員サービス")
              }}
            </div>
            <!--            <div class="color666 margin-bottom-6 cursorPointer" @click="$fun.routerToPage('/photography', true)">-->
            <!--              {{ $fanyi("写真撮影（中国）") }}-->
            <!--            </div>-->
            <!--            <div class="color666 margin-bottom-6 cursorPointer" @click="$fun.routerToPage('/logisticsTracking', true)">-->
            <!--              {{ $fanyi("物流追跡") }}-->
            <!--            </div>-->
            <!--            <div class="color666 margin-bottom-6 cursorPointer"-->
            <!--                 @click="$fun.routerToPage('/FBADirectDeliveryService', true)">{{ $fanyi("FBA直送サービス") }}-->
            <!--            </div>-->
            <!--            <div class="color666 cursorPointer margin-bottom-6">{{ $fanyi("無在庫について") }}</div>-->
            <div class="color666 cursorPointer margin-bottom-6" @click="pageJump('/photography', true)">
              写真撮影
            </div>
            <div class="color666 cursorPointer margin-bottom-6" @click="pageJump('/tollVipApplyFor', true)">
              {{ $fanyi("定額会員") }}
            </div>
            <div class="color666 cursorPointer margin-bottom-6" @click="pageJump('/aboutNotInStock', true)">
              無在庫について
            </div>
            <div class="color666 cursorPointer" @click="pageJump('/accountSynchronization', true)">
              RAKUAMRT&1688
            </div>
          </div>
          <div class="boxOpt">
            <div class="fontSize18 color333 fontWeightBold" style="margin-bottom: 29px">{{ $fanyi("收费体系") }}</div>
            <div class="color666 margin-bottom-6 cursorPointer"
                 @click="pageJump('/comisiones-rakumart')">
              {{ $fanyi("手数料") }}
            </div>
            <div class="color666 margin-bottom-6 cursorPointer"
                 @click="pageJump('/internationalLogistics')">{{ $fanyi("国際送料詳細") }}
            </div>
            <div class="color666  cursorPointer margin-bottom-6" @click="pageJump('/inspectionDetail')">
              {{ $fanyi("普检精检详细说明") }}
            </div>
            <div class="color666  cursorPointer margin-bottom-6" @click="pageJump('/option')">
              {{ $fanyi("附加服务说明") }}
            </div>
            <div class="color666  cursorPointer" @click="pageJump('/storageInstructions')">
              {{ $fanyi("仓库保管说明") }}
            </div>
          </div>
          <div class="boxOpt">
            <div class="fontSize18 color333 fontWeightBold" style="margin-bottom: 29px">{{ $fanyi("会社案内") }}</div>
            <div class="color666 margin-bottom-6 cursorPointer" @click="pageJump('/japanCompanyIntroduce')">
              {{ $fanyi("日本会社") }}
            </div>
            <div class="color666 margin-bottom-6 cursorPointer" @click="pageJump('/chinaCompanyIntroduce')">
              {{ $fanyi("中国会社") }}
            </div>
            <div class="color666 margin-bottom-6 cursorPointer" @click="pageJump('/questionAndAnswer')">
              {{ $fanyi("よくある質問") }}
            </div>
            <div class="color666 margin-bottom-6 cursorPointer"
                 @click="blogPageJump">
              BLOG
            </div>
            <div class="color666 cursorPointer" @click="pageJump('/contactUs', true)">{{ $fanyi("お問い合わせ") }}</div>
          </div>
        </div>
      </div>
      <div class="plateNumber">
        <div>
          <img src="../../assets/ems.png" class="img1">
          <img src="../../assets/ocs.png" class="img2">
          <img src="../../assets/kagen.png" class="img3">
          <img src="../../assets/sgh.png" class="img4">
          <img src="../../assets/foot-img/blackCatLogo.png" class="img5">
          <img alt="" class="img6" src="../../assets/1688/1688logo.svg">
          <img src="../../assets/taobaoLogo.png" alt="" class="img7">
          <img src="../../assets/tmallLogo.png" alt="" class="img8">
        </div>
        <div>
          <img src="../../assets/bank1.png" alt="" class="img9">
          <img src="../../assets/paypal.png" alt="" class="img10">
          <img src="../../assets/bank2.png" class="img11" alt="">
          <img src="../../assets/paypay.png" class="img12" alt="">
          <img src="../../assets/ccbank.png" class="img13" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    pageJump(val) {
      window.open(val, "_blank");
    },
    blogPageJump() {
      let user_token = localStorage.getItem('japan_user_token');
      let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
      if (user_token != null) {
        window.open(`https://blog.rakumart.com/#/?marker=rakumart&userId=${userData.operation_id}`, "_blank")
      } else {
        window.open('https://blog.rakumart.com/#/?marker=rakumart', "_blank")
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../css/mixin.scss";

.technicalServiceSupport {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;

  div {
    width: 600px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    color: #333333;
    line-height: 40px;
    font-size: 16px;
    text-align: center;
  }
}

.footerBackground {
  background-color: white;
  border-top: solid 1px #e8e8e8;
  padding-top: 80px;
  min-width: 1400px;

  .margin-bottom-6 {
    margin-bottom: 6px;
  }

  .footerLinkBox {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .footerLinkLeftBox {
      margin-right: 292px;

      img {
        width: 150px;
        height: 39px;
        margin-bottom: 21px;
      }

      div {
        color: #666666;
        font-size: 14px;
      }
    }

    .footerLinkRightBox {
      width: 891px;
      justify-content: space-between;

      .boxOpt {
        font-size: 14px;

        div:hover {
          color: #b4272b;
        }

        .logoImg {
          width: 160px;
          height: 40px;
          margin-bottom: 20px;
        }

        .imgBox {
          display: flex;
          align-items: center;

          img {
            margin-right: 16px;
          }
        }

        p {
          color: #767676;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.8em;
          margin-bottom: 14.4px;
        }

        .boxTitle {
          color: #1a289d;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 20px;
        }

        .boxSpan {
          font-size: 14px;
          font-weight: 500;
          color: #767676;
          margin-bottom: 15px;
          cursor: pointer;
        }
      }
    }
  }

  .plateNumber {
    width: 1400px;
    margin: 49px auto 0;
    border-top: solid 1px #e1e1e1;
    padding-bottom: 60px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      .img1 {
        width: 116px;
        height: 25px;
      }

      .img2 {
        width: 66px;
        height: 25px;
      }

      .img3 {
        width: 26px;
        height: 25px;
      }

      .img4 {
        width: 34px;
        height: 25px;
      }

      .img5 {
        width: 38px;
        height: 25px;
      }

      .img6 {
        width: 121px;
        height: 25px;
      }

      .img7 {
        width: 39px;
        height: 25px;
      }

      .img8 {
        width: 164px;
        height: 25px;
      }

      .img9 {
        width: 166px;
        height: 25px;
      }

      .img10 {
        width: 88px;
      }

      .img11 {
        width: 124px;
      }

      .img12 {
        width: 102px;
      }

      .img13 {
        width: 134px;
      }

      .img14 {
        width: 131px;
      }
    }
  }
}
</style>
